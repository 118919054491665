@import './reset.css';

html, body {
    background-color: #303030;
    color: #FFFFFF;
}

#app {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
